//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../redux/store';
import axios from 'axios';
import { setUser } from '../redux/actions/userActions';
import LoadingIndicator from '../components/widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../redux/actions/appActions';
import withRouter from '../helpers/withRouter';

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

const Container = tw(ContainerBase)
`min-h-screen bg-secondary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-12 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;
const DividerTextContainer = tw.div `my-2 py-6 text-center relative`;
const DividerText = tw.div `leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`cursor-pointer mt-5 tracking-wide font-semibold bg-secondary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-secondary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div `sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div `
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;






export class UserPasswordVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: { email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
            messages: {},
        };
    }



    handleSubmitVerifyCode() {
        let messages = {};
        let fields = {};
        var fData = new URLSearchParams();
        const state = store.getState();
        fData.append('email', state.user.email);
        fData.append('password_reset', this.state.fields['password_reset']);
        fData.append('verify_code', this.state.fields['verify_code']);
        console.log('VERIFY CODE SUBMIT: : : ')
        console.log(this.state.fields);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                url: 'http://127.0.0.1:8080/bff_api/user_reset_password_verify.php', // for testing usage switchonoff
                // // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_reset_password_verify.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success

                if (response.status === 200) {
                    // setUser(this.id, this.username, this.email, this.newsletter, this.subscription, this.subscription_plan_id);
                    console.log(response.data);
                } else if (response.data.match(/code\s*expired/i)) {
                    fields['verify_sent'] = false;
                    messages['status'] = response.data;
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                this.setState({
                    fields: { email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
                    messages: { status: 'Verified - reset password successful! Please login again with username/email and new password.' },
                })
                const {
                    history
                } = this.props;

                //use navigate
                const { navigate } = this.props;                     
                navigate('/pages/login'); 

            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }


    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }

    handleResetPasswordVerifyCode(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationResetPasswordVerifyCode()) {
            toggleLoadingIndicator(!is_loading_indicator);
            this.handleSubmitVerifyCode();
        }
    }


    handleValidationResetPassword() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //Password
        if (!fields['email']) {
            formIsValid = false;
            messages['email'] = 'Cannot be empty';
        }

        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }



    handleValidationResetPasswordVerifyCode() {
        let fields = this.state.fields;
        let messages = {};
        let formIsValid = true;
        // console.log('FIELDS: : : ')
        // console.log(fields);
        //Password
        if (!fields["password_reset"]) {
            formIsValid = false;
            messages["password_reset"] = "Cannot be empty";
        }

        if (typeof fields["password_reset"] !== "undefined") {
            if (!fields["password_reset"].length >= 8) {
                formIsValid = false;
                messages["password_reset"] = "Minimum length of password must be 8.";
            }
            if (!fields["password_reset"].match(/[a-zA-Z]/) || !fields["password_reset"].match(/[0-9]/) || !fields["password_reset"].match(/_|-|!|\*|\./)) {
                formIsValid = false;
                messages["password_reset"] = "Must include letters, number(s), and at least one symbol: '!', '_', '-', '*'";
            }
            //check for 
        }

        if (!fields["confirm_password_reset"]) {
            formIsValid = false;
            messages["confirm_password_reset"] = "Cannot be empty";
        } else if (typeof fields["confirm_password_reset"] !== "undefined") {
            if (fields["password_reset"] !== fields["confirm_password_reset"]) {
                formIsValid = false;
                messages["confirm_password_reset"] = "Password confirmation does not match";
            }
        }

        this.setState({ messages: messages });
        return formIsValid;
    }



    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        fields['email'] = fields['email'];
        // console.log(field)
        this.setState({ fields });
        // console.log(this.state.fields);
    }

    handleGoBack() {
         //use navigate
                const { navigate } = this.props;                     
                navigate('/pages/login'); 
    }

    componentDidMount() {
        const state = store.getState();
        if (!state.user.email || state.user.email === '') {
            console.log('Shouldnt be on this page');
        }
    }



    render() {
            // const { setUserAction } = this.props;
            // const { id, username, email, newsletter, subscription, fields, messages } = this.props;        
            const {
                toggleLoadingIndicator,
                is_loading_indicator,
            } = this.props;

            const state = store.getState();

            return (
                <AnimationRevealPage>
                {/*<Helmet>
                    <meta charSet="utf-8" />
                    <title>BSE - User Register Verify</title>
                    <link rel="canonical" href="https://bigstreamentertainment.com/SignUpVerify" />
                    <meta name="description" content="BSE - Big Stream Entertainment streaming and entertainment portal user register verify page (Stock: BSEG)" />
                    <meta name="keywords" content="big stream, bseg, big screen entertainment, big stream, entertainment, user register verify" />
                </Helmet>*/}
                <Container>
                    <Content>
                        <MainContainer>
                            {state.user.email && 
                                <MainContent>
                                    <Heading>Verify Email</Heading>
                                    <FormContainer>
                                        <DividerTextContainer>
                                            <DividerText>
                                                <p><strong>Email verification sent to:</strong><br /><br /><strong>{state.user.email}</strong><br /></p>
                                            </DividerText>
                                        </DividerTextContainer>
                                        <div style={{maxWidth: '350px', margin: '0 auto'}}>
                                            <p style={{textAlign: 'justify', marginBottom: '1rem'}}>Check your inbox (or spam) for reset password email with verify code.</p>
                                            <p style={{textAlign: 'justify', marginBottom: '1rem'}}>Enter new password, confirm password, and enter verify code from email, click Update Password to submit.</p>
                                        </div>
                                        <Form>
                                            <label><strong>New Password</strong></label><br/>
                                            <label><small>Minimum length of 8, must include letters, and one number, and acceptable symbol: ! _ - . *</small></label><br/>
                                            <Input type="password" name="password_reset" value={this.state.fields["password_reset"]} onChange={this.handleChange.bind(this, "password_reset")}/><br/>
                                            <label><strong>Confirm Password</strong></label><br/>
                                            <Input type="password" name="confirm_password_reset" value={this.state.fields["confirm_password_reset"]} onChange={this.handleChange.bind(this, "confirm_password_reset")}/><br/>
                                            <label><strong>Verify Code</strong></label><br/>
                                            <Input type='text' name='verify_code' value={this.state.fields['verify_code']} onChange={this.handleChange.bind(this, 'verify_code')}/><br/>
                                            <p style={{minHeight: '22px'}}><span style={{color: "red"}}>{this.state.messages["password_reset"]}</span> <span style={{color: "red"}}>{this.state.messages["confirm_password_reset"]}</span></p><br />
                                            {this.state.fields['verify_code'].length === 0 ? <SubmitButton name="verify" type="submit" className="disabled" value="Verify Code" disabled >Verify Code</SubmitButton> : <SubmitButton name="verify" type="submit" onClick={e => this.handleResetPasswordVerifyCode(e, is_loading_indicator, toggleLoadingIndicator)} value="Verify Code" >Update Password</SubmitButton>}
                                        </Form>
                                    </FormContainer>
                                </MainContent>
                            }
                            {(!state.user.email || state.user.email === '') && 
                                <MainContent>
                                    <Heading>No Email to Verify Password</Heading>
                                    <FormContainer>
                                        <DividerTextContainer>
                                            <DividerText>
                                                <p><strong>There is no email to verify.</strong><br /></p>
                                            </DividerText>
                                        </DividerTextContainer>
                                        <div style={{maxWidth: '350px', margin: '0 auto'}}>
                                            <p style={{textAlign: 'justify', marginBottom: '1rem'}}>You probably reached this page by accident, if so and are looking to update your password.  Please go to Login page.</p>                                        
                                        </div>
                                        <Form>
                                            <SubmitButton type="submit" value="Go to Login" onClick={e => this.handleGoBack()}>Go to Login</SubmitButton>
                                        </Form>
                                    </FormContainer>
                                </MainContent>
                            }
                        </MainContainer>
                        <IllustrationContainer>
                            <IllustrationImage imageSrc={illustration} />
                        </IllustrationContainer>
                    </Content>
                </Container>
            </AnimationRevealPage>
        );
    }
}


export const mapStateToProps = state => ({
    id: state.user.id,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    email: state.user.email,
    phone: state.user.phone,
    add_street1: state.user.add_street1,
    add_street2: state.user.add_street2,
    city: state.user.city,
    state: state.user.state,
    zip_code: state.user.zip_code,
    country: state.user.country,
    tier: state.user.tier,
    is_loading_indicator: state.app.is_loading_indicator,
});

export const mapDispatchToProps = dispatch => ({
    setUser: (id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier) => dispatch(setUser(id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPasswordVerify));