import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300 cursor-pointer`}
  svg {
    ${tw`w-6 h-6 cursor-pointer`}
  }
`);
const Answer = motion(tw.dd` text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const a = tw``

export default ({
  subheading = "FAQS",
  heading = "Investing in BFF Projects",
  description = "",
  faqs = [
    {
      question: "What is the minimum investment?",
      answer:
        <>The minimum investment is $100 which is the minimal amount to be able to fund the project, and still be large enough for ancillary costs for recording the investment with our transfer agent <a style={{textDecoration: "underline"}} href='https://www.colonialstock.com/' target='_blank'>Colonial Stock</a></>,
        link: false,
        linkname: false

    },
    {
      question: "Can I invest more than one time?",
      answer:
        <>Yes you may invest as many times as you want under your legal name, and under the same investor ID provided to you when you signed up to Big Film Fund.  You may invest up to your legal limit under <a style={{textDecoration: "underline"}} href="https://www.sec.gov/education/smallbusiness/exemptofferings/regcrowdfunding" target="_blank">Regulation CF</a></>
    },
    {
      question: "What updates can I expect from my investment in BFF projects?",
      answer:
        <>Under <a style={{textDecoration: "underline"}} href="https://www.sec.gov/education/smallbusiness/exemptofferings/regcrowdfunding" target="_blank">Regulation CF</a>, each LLC created for each investment opportunity is required to file an annual report with the SEC (Securities and Exchange Commission). BFF will also send periodically to your registered email, investment updates about investments and projects.</>
    },
    {
      question: "Once invested, will I make money if the project generates revenue?",
      answer:
        <>Yes. As an owner of a BFF investment, you would be entitled to a share of thr profits per the operating agreement. Management of each investment will deterine when distributions can be made.</>
    },
    {
      question: "How do BFF LLCs/film and media projects generate revenue and profit?",
      answer:
        <>Films, television, all media, generate revenue from distribution channels, customers, streaming sites/apps, venue/theater tickets, direct sales and rentals (download, or DVD/Blu-Ray), fees from domestic and intenational cable and TV station licensing.  Projects can also provide other ancillary revenue from merchandise sales, and or gaming etc.</>
    },
    {
      question: "Are there any guarantees to investing in a project or film fund?",
      answer:
        <>No. This is an investment in early-stage LLCs/films and can be extremely risky. Please consult with your independent financial advisor before making any decisions on investments. Do not invest more than you can afford to lose.</>
    },
    {
      question: "How long are LLC investment units available to purchase?",
      answer:
        <>Once the LLC has been funded to complete the project its intended, no further investors will be taken.  All investments are on a first come first serve basis. We also reserve the right to end investment funding early, and/or to cancel the project.</>
    },
    {
      question: "If the project is cancelled before fully funded and developed, will my investment be returned.",
      answer:
        <>If a project does not receive full funding, we reserve the right to suspend, and/or cancel the investment.  In the case we suspend or cancel the investment, we will return all investment amounts minus financial transfer fees undertaken during the course of investing.</>
    },
    {
      question: "How can I invest, and pay for an investment?",
      answer:
        <>Currently we are setting up all of our funding channels.  Please contact us directly to get started.</>
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}                
                className="group"
              >
                <Question                  
                >
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
