import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = styled.div`
  ${tw`px-4 flex flex-09 flex-col justify-center items-center `}
  position: relative; top: 20px;
`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Notification = tw.span`inline-block mt-24 text-gray-100 border-b-4 border-blue-500 font-medium text-lg`;
const MediaHeader = tw.span`w-full inline-block text-center text-gray-100 border-b-4 border-blue-500 font-medium text-xl`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 sm:mt-1 lg:mt-0 lg:pl-8`;
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-4 text-sm sm:text-base sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-secondary-500 text-gray-100 hocus:bg-secondary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  return (    
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        {/*<StyledHeader links={navLinks} />*/}
        <Content>
          <Notification>Equity Based Fractionalized Funding for Media, Film, Television, and More</Notification>
          <div style={{color: '#fff', paddingBottom: '5px'}}>
            <p>Invest, earn and support film makers, production crews, writers, & more.</p>
            <p>Investing into BFF projects allow for:</p>
            <ul>
              <li style={{padding: '0 0 0 10px', margin: '0 0 0 20px', listStyleType: 'disclosure-closed'}}>Potential of high ROI</li>
              <li style={{padding: '0 0 0 10px', margin: '0 0 0 20px', listStyleType: 'disclosure-closed'}}>Possibility of recurring revenue</li>
              <li style={{padding: '0 0 0 10px', margin: '0 0 0 20px', listStyleType: 'disclosure-closed'}}>Investors get paid first</li>
              <li style={{padding: '0 0 0 10px', margin: '0 0 0 20px', listStyleType: 'disclosure-closed'}}>Exciting perks, rewards & more</li>
            </ul>
            <br />
            
          </div>
          <RightColumn>
            <MediaHeader>Featured <em>Miracle Ranch</em></MediaHeader>
            <StyledResponsiveVideoEmbed
              url=""
              background="transparent"
            />
          </RightColumn>
          <PrimaryAction href="/pages/signup">Sign-Up to BFF</PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
