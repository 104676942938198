import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

import bff_invest_2024_cinedrella from '../../images/invest/bff_invest_2024_cinedrella.jpg';
import bff_invest_2024_earth_angels from '../../images/invest/bff_invest_2024_earth_angels.jpg';
import bff_invest_2024_keytochristmas from '../../images/invest/bff_invest_2024_keytochristmas.jpg';
import bff_invest_2024_wonderful_wife from '../../images/invest/bff_invest_2024_wonderful_wife.jpg';

import { PrimaryButton } from "components/misc/Buttons";

const Container = tw.div `relative`;
const Content = tw.div `max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div `flex flex-col items-center md:items-stretch md:flex-row flex-wrap gap-8 justify-center`;
// const ThreeColumn = styled.div(props => [
//   `gap: 1%; justify-content: center;`,
//   tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`
// ]);

// const Column = tw.div`mt-24 lg:w-1/4`;
const Column = styled.div(props => [
    `min-width: 250px;`,

]);
const HeadingInfoContainer = tw.div `flex flex-col items-center`;
const HeadingDescription = tw.p `mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = styled.div(props => [
    `max-width: 250px`,
    ``
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}"); min-height: 400px;`,
    tw `bg-cover bg-center h-80 lg:h-96 rounded`
]);
const Category = tw.div `mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4 `mt-2 leading-relaxed font-bold text-lg`;
const Budget = tw.h4 `mt-2 leading-relaxed font-bold text-lg`;
const Link = tw.a `inline-block mt-2 text-sm text-secondary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-secondary-500`;

const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
)
`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
)
`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
    blogPosts = [{
            imageSrc: bff_invest_2024_cinedrella,
            category: "Fairy Tale",
            title: "Cinderella",
            url: "/projects/investsinglecinderella",
            budget: "$1,000,000"
        },
        {
            imageSrc: bff_invest_2024_earth_angels,
            category: "Sci-Fi Young Adult",
            title: "Earth Angels",
            url: "/projects/investsingleearthangels",
            budget: "$450,000"
        },
        {
            imageSrc: bff_invest_2024_keytochristmas,
            category: "Adventure Mystery Christmas",
            title: "Key to Christmas",
            url: "/projects/investsinglekeytochristmas",
            budget: "$650,000"
        },
        {
            imageSrc: bff_invest_2024_wonderful_wife,
            category: "Hallmark Lifetime Light Romance",
            title: "It's a Wonderful Wife",
            url: "/projects/investsingleitsawonderfulwife",
            budget: "$520,000"
        }
    ]
}) => {
    const [visible, setVisible] = useState(4);
    const onLoadMoreClick = () => {
        setVisible(v => v + 4);
    };
    const onLoadLessClick = () => {
        setVisible(v => v - 4);
    };

    return (
        <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Invest in Individual Movies</HeadingTitle>
          <HeadingDescription>Interested in a single project to invest in.</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.slice(0, visible).map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Budget>{post.budget}</Budget>
                <Link href={post.url}>Invest Now</Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
        {visible < blogPosts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        {(visible >= blogPosts.length && blogPosts.length > 4) && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadLessClick}>Load Less</LoadMoreButton>
            </ButtonContainer>
          )}
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
    );
};