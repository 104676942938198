//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../redux/store';
import axios from 'axios';
import { setUser } from '../redux/actions/userActions';
import LoadingIndicator from '../components/widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../redux/actions/appActions';
import withRouter from '../helpers/withRouter';

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

const Container = tw(ContainerBase)
`min-h-screen bg-secondary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-12 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;
const DividerTextContainer = tw.div `my-2 py-6 text-center relative`;
const DividerText = tw.div `leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`cursor-pointer mt-5 tracking-wide font-semibold bg-secondary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-secondary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div `sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div `
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;


export class SignUp extends Component {

    // static propTypes = {
    //     match: PropTypes.object.isRequired,
    //     location: PropTypes.object.isRequired,
    //     history: PropTypes.object.isRequired
    // }

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: '',
                password: '',
                confirm_password: ''
            },
            messages: {}
        };
    }


    handleFormSubmit(event) {

        // event.preventDefault();
        var fData = new URLSearchParams();
        console.log(this.state);
        fData.append('email', this.state.fields['email']);
        fData.append('password', this.state.fields['password']);
        fData.append('tier', 0);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                method: 'post',
                url: 'http://localhost:8080/bff_api/user_register.php', // for testing usage switchonoff 
                // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log('API Register connect...');
                console.log(response.data) // for console usage switchonoff
                if (typeof response.data === 'string') {
                    if (response.data.match(/duplicate/i)) {
                        this.setState({ messages: {} });
                        let messages = {};
                        if (response.data.match(/duplicate.*email/i)) {
                            messages["email"] = "Error! - email already in use";
                        }
                        this.setState({ messages: messages });
                    }
                    const {
                        toggleLoadingIndicator,
                        is_loading_indicator,
                    } = this.props
                    toggleLoadingIndicator(!is_loading_indicator);
                } else if (response.data === 1) {
                    const {
                        setUser,
                        toggleLoadingIndicator,
                        is_loading_indicator,
                        // history
                    } = this.props;

                    // let fields = this.state.fields;

                    console.log(this.state.fields) // for console usage switchonoff
                    setUser(this.state.fields["id"], '', '', this.state.fields["email"], '', '', '', '', '', '', '', this.state.fields["tier"]);
                    const { navigate } = this.props; 
                    // <Navigate 
                    //     to={'/pages/signupverify'}
                    //     state={this.state}
                    //   />
                    navigate('/pages/signupverify');
                    
                    //setUserAction add in everything you received back from the API for newly activated registered users
                    
                    // history.push(Routes.SignUpverify);
                }


            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }

    handleValidation() {
        let fields = this.state.fields;
        let messages = {};
        let formIsValid = true;
        //First Name validation empty
        console.log('handleValidation')
        //username validation empty

        //Password
        if (!fields["password"]) {
            formIsValid = false;
            messages["password"] = "Cannot be empty";
        }
        if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].length >= 8) {
                formIsValid = false;
                messages["password"] = "Minimum length of password must be 8.";
            }
            if (!fields["password"].match(/[a-zA-Z]/) || !fields["password"].match(/[0-9]/) || !fields["password"].match(/_|-|!|\*|\./)) {
                formIsValid = false;
                messages["password"] = "Must include letters, number(s), and at least one symbol: '!', '_', '-', '*'";
            }
            //check for 
        }
        if (!fields["confirm_password"]) {
            formIsValid = false;
            messages["confirm_password"] = "Cannot be empty";
        } else if (typeof fields["confirm_password"] !== "undefined") {
            if (fields["password"] !== fields["confirm_password"]) {
                formIsValid = false;
                messages["confirm_password"] = "Password confirmation does not match";
            }
        }
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            messages["email"] = "Error! - cannot be empty";
        }
        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                messages["email"] = "Error! - email is not valid format";
            }
        }
        this.setState({ messages: messages });
        return formIsValid;
    }

    checkSubmit(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();

        if (this.handleValidation()) {
            console.log('checkSubmit')
            toggleLoadingIndicator(!is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmit();
        } else {
            // alert("Form has messages.");
        }
    }
    //todo  do not handle every single change to set the state its pointless... just set it after its validated above 
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
        // console.log(this.state)
    }


    render() {
        const {
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;
        return (
            <AnimationRevealPage>
              <Container>
                <Content>
                  <MainContainer>
                    <MainContent>
                      <Heading>BFF Sign Up</Heading>
                      <FormContainer>
                        <DividerTextContainer>
                          <DividerText>Sign up with your e-mail</DividerText>
                        </DividerTextContainer>
                        
                        <Form>                    
                              <label>Email</label> <span style={{color: "red"}}>{this.state.messages["email"]}</span><br />
                              <Input type="email" name="email"  value={this.state.email} onChange={this.handleChange.bind(this, "email")}/>
                              <br />
                              <label>Password</label> <span style={{color: "red"}}>{this.state.messages["password"]}</span><br />
                              <small>Minimum length of 8, must include letters, and one number, and acceptable symbol: ! _ - . *</small><br/>
                              <Input type="password" name="password"  value={this.state.password} onChange={this.handleChange.bind(this, "password")}/>
                              <br />
                              <label>Confirm Password</label> <span style={{color: "red"}}>{this.state.messages["confirm_password"]}</span><br />
                              <Input type="password" name="confirm_password"  value={this.state.confirm_password} onChange={this.handleChange.bind(this, "confirm_password")}/>
                              <br />
                              <SubmitButton className="pleft" type="submit" onClick={e => this.checkSubmit(e, is_loading_indicator, toggleLoadingIndicator)} value="Register to BigStream">Submit</SubmitButton>
                          </Form>
                          {/*<p className="pleft"><small>Note: All newly registered users will automatically be subscribed to the Big Stream newsletter. <br />If you wish to un-subscribe you may in your user profile.</small></p>*/}
                          <p tw="mt-6 text-xs text-gray-600 text-center">
                            I agree to abide by BFF's{" "}
                            <a href='/pages/termsofservice' tw="border-b border-gray-500 border-dotted">
                              Terms of Service
                            </a>{" "}
                            and its{" "}
                            <a href='/pages/privacypolicy' tw="border-b border-gray-500 border-dotted">
                              Privacy Policy
                            </a>
                          </p>

                          <p tw="mt-8 text-sm text-gray-600 text-center">
                            Already have an account?{" "}
                            <a href="/pages/login" tw="border-b border-gray-500 border-dotted">
                              Sign In
                            </a>
                          </p>
                      </FormContainer>
                    </MainContent>
                  </MainContainer>
                  <IllustrationContainer>
                    <IllustrationImage imageSrc={illustration} />
                  </IllustrationContainer>
                </Content>
              </Container>
            </AnimationRevealPage>
        );
    }
}

export const mapStateToProps = state => ({
    id: state.user.id,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    email: state.user.email,
    phone: state.user.phone,
    add_street1: state.user.add_street1,
    add_street2: state.user.add_street2,
    city: state.user.city,
    state: state.user.state,
    zip_code: state.user.zip_code,
    country: state.user.country,
    tier: state.user.tier,
    is_loading_indicator: state.app.is_loading_indicator,
});
export const mapDispatchToProps = dispatch => ({
  setUser: (id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier) => dispatch(setUser(id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier)),
  toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
// export default UserLogin;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));