import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";

//content
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
//content images
import TheCover from "../images/invest/bff_invest_2024_therhymer.jpg";
import BannerMain from "./images/miracleranch/banner_main.jpg";
import BannerCat1 from "./images/miracleranch/banner_cat1.jpg";
import BannerCat2 from "./images/miracleranch/banner_cat2.jpg";
import BannerCat3 from "./images/miracleranch/banner_cat3.jpg";

//play button and modals
import ReactModalAdapter from "../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../helpers/ResponsiveVideoEmbed.js";
import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/dot-pattern.svg";

import InvestFAQ from "./faqs/INVEST_FAQ_TwoColumnPrimaryBackground.js"
import Testimony from "components/testimonials/SimplePrimaryBackground.js"
//cast
import Cast from "./cards/ProfileThreeColGrid.js"
//cast social icons
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
//cast images
import CastImg1 from "./images/miracleranch/cast_samelliot.jpg";
import CastImg2 from "./images/miracleranch/cast_matthewmodine.jpg";
import CastImg3 from "./images/miracleranch/cast_jennaortega.jpg";
import CastImg4 from "./images/miracleranch/cast_dovecameron.jpg";
//previous video heros
import Video1 from "./hero/BackgroundAsImage.js"
import Video1IMG from "./images/miracleranch/vid_moonrise.jpg"
import Video2 from "./hero/BackgroundAsImage.js"
import Video2IMG from "./images/miracleranch/vid_paintedhorses.jpg"
import Video3 from "./hero/BackgroundAsImage.js"
import Video3IMG from "./images/miracleranch/vid_magicmax.jpg"

//elements
const HeadingRow = tw.div`flex mt-4`;
const Heading = tw(SectionHeading)`text-secondary-500`;
const Posts = tw.div`mt-6 sm:-mr-8`;
const DescContainer = tw.div`mt-4 p-2`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${PostHero} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${PostMain} {
        ${tw`sm:flex-row! h-full`}
      }
      ${Image} {
        ${tw`sm:h-auto sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${ImageDesc} {
        ${tw`sm:h-auto sm:min-h-full mt-2`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const PostHero = tw.div`flex flex-col bg-gray-100 rounded-lg`;
const PostMain = tw.div`flex flex-col rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;

const ImageDesc = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center`}
`;
const Info = tw.div`p-8 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-secondary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-secondary-500 after:w-8`;
const Funded = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Budget = tw.div`mt-1 font-black text-2xl text-secondary-500 group-hover:text-secondary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`mt-16 flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
const InvestButton = tw(PrimaryButton)``;

//play video and modal const
const WatchVideoButton = styled.button`
  ${tw`ml-8 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-secondary-900 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-[70px] right-[-10px] mt-8 mr-8 text-red-500 hocus:text-red-900`;


//decorator blobs
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-secondary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

//highlights
const HighlightedText = tw.span`bg-secondary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;



export default ({

  headingText=<>Invest in <HighlightedText>Miracle Ranch</HighlightedText></>,  
  watchVideoButtonText="Watch Video",
  watchVideoYoutubeUrl="https://www.youtube.com/embed/X3sK_-cfZHY",
  posts = [
    {
      imageSrc:
        BannerMain,
      category: "Faith",
      funded: "Amount funded: $0.00",
      budget: "Budget: $750,000",
      description:
        "A troubled young woman is determined to self-destruct and take her foster family with her when a miraculous rescue horse teaches her to live, love, and thrive again.",
      longdescription: 
         [  
            {
              category: 'Setting',
              img: BannerCat1,
              para: "When Mark and Wendy Stone are bequeathed the Friendly Barn Rescue Center for at-risk teens, and along with it three-hundred acres of lush horse property, they must decide whether to close the doors or keep it open - in spite of the challenges.",
              
            },
            {
              category: 'The Struggle',
              img: BannerCat2,
              para: "They meet Master Horse Whisperer Buddy, and his knack for finding the \"right horse at the right time\". It makes no financial sense to keep the ranch, but the Stones decide to trust Buddy’s instinct, and soon the Stones are rescuing horses bound for slaughter and putting them to great use as equine therapy horses for the kids.",
              
            },
            {
              category: 'Heartfelt',
              img: BannerCat3,
              para: "The Stones form a riding team to go up against their well-to-do arch-rivals in the Pony Express riding competition. It will be a race for herself, as well as for the stability of the ranch, but Natalie and her horse might be the only ones who can win.  All the while trying to give love and support to the kids that now depend on the Stones, and the Miracle Ranch."
              
            }
         ],
      metrics:
      [
        {
          
        }
      ],
      url: "/pages/contactuspage",
      featured: true,
      testimonials: [
        {
          customerName: "Rhymer Rogers",
          customerProfile: "Writer, Creator",
          imageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
          quote: "I look to inspire hope with Miracle Ranch.  With so many struggling and be down, to come back and realize the good in this world."
        }
      ],
      cards: [
        {
          imageSrc: CastImg1,
          position: "Lead",
          name: "Sam Elliot",
          links: [
            {
              url: "https://twitter.com",
              icon: TwitterIcon,
            },
            {
              url: "https://linkedin.com",
              icon: LinkedinIcon,
            },
            {
              url: "https://github.com",
              icon: GithubIcon,
            },
          ],
        },
        {
          imageSrc:  CastImg2,
          position: "Part",
          name: "Matthew Modine",
          links: [
            {
              url: "https://twitter.com",
              icon: TwitterIcon,
            },
            {
              url: "https://linkedin.com",
              icon: LinkedinIcon,
            },
            {
              url: "https://github.com",
              icon: GithubIcon,
            },
          ],
        },
        {
          imageSrc:  CastImg3,
          position: "Chief Operating Officer",
          name: "Part",
          links: [
            {
              url: "https://twitter.com",
              icon: TwitterIcon,
            },
            {
              url: "https://linkedin.com",
              icon: LinkedinIcon,
            },
            {
              url: "https://github.com",
              icon: GithubIcon,
            },
          ],
        },
        {
          imageSrc:  CastImg4,
          position: "Part",
          name: "Dove Cameron",
          links: [
            {
              url: "https://twitter.com",
              icon: TwitterIcon,
            },
            {
              url: "https://linkedin.com",
              icon: LinkedinIcon,
            },
            {
              url: "https://github.com",
              icon: GithubIcon,
            },
          ]
        }
      ]
    }
  ]
}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <AnimationRevealPage>
      {/*<Header />*/}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>

          <Posts>
            {posts.map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <PostHero className="group">
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <Budget>{post.budget}</Budget>
                    <Funded>{post.funded}</Funded>
                    <Description>{post.description}</Description>
                    <ButtonContainer>
                      <InvestButton href={post.url}>Invest Now</InvestButton>
                      <WatchVideoButton onClick={toggleModal}>
                        <span className="playIconContainer">
                          <PlayIcon className="playIcon" />
                        </span>
                        <span className="playText">{watchVideoButtonText}</span>
                      </WatchVideoButton>
                    </ButtonContainer>
                  </Info>
                </PostHero> 
                <PostMain>
                  
                    {post.longdescription.map((desc, index2) => (
                      <DescContainer key={'p' + index2}>
                        <Category>{desc.category}</Category>
                        <ImageDesc imageSrc={desc.img} />
                        <p>{desc.para}</p>
                      </DescContainer>
                    ))}                    
                </PostMain>
                <InvestFAQ />
                <Cast 
                  heading = "Targeted Cast"
                  subheading = "The Project Team"
                  description = "The Producers of Miracle Ranch have substantial direct-to-agency, talent and overall industry relationships including world-wide talent and management agencies all of whom have expressed enthusiastic interest in providing talent packaging our Project."
                  cards={post.cards}
                />
                <Heading>Mark Cramer's <HighlightedText>Previous Works</HighlightedText></Heading>
                <Video1
                  header="Moonrise"
                  subHeader="Official Trailer"
                  videoURL="https://www.youtube.com/embed/5f5Z05MAk9A"
                  backgroundIMG={Video1IMG}
                />
                <Video2
                  header="Painted Horses"
                  subHeader="Official Trailer"
                  videoURL="https://www.youtube.com/embed/2vP1dFHq1og"
                  backgroundIMG={Video2IMG}
                />
                <Video3
                  header="Magic Max"
                  subHeader="Official Trailer"
                  videoURL="https://www.youtube.com/embed/wTyBcdhS0W4"
                  backgroundIMG={Video3IMG}
                />
                <Testimony 
                  heading="Our Team"
                  subheading="Miracle Ranch"
                  description="Read about what the writers, producers, and creators think about their vision and thoughts of Miracle Ranch"
                  testimonials={post.testimonials}
                />
              </PostContainer>
            ))}
          </Posts>
          <DecoratorBlob1 />
        </ContentWithPaddingXl>        
        

        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>        

      </Container>
    </AnimationRevealPage>
  );
};

