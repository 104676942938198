import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";

import MainFeature1 from "components/cards/TwoColWithButton_AboutUs.js";
// import FAQS from "components/faqs/SingleCol.js";
// import FAQS from "components/faqs/TwoColumnPrimaryBackground.js";
import FAQS from "components/faqs/FAQ_SimpleWithSideImage_AboutUs_Promo.js";
import FAQBFF from "components/faqs/FAQ_SingleCol_AboutUs_BFF";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/cards/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";


import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      {/*{/*<Header />*/}*/}
      <MainFeature1
        description="Equity Ownership Platform: Our platform offers investors the opportunity to become actual owners in our film, television, gaming, and media projects."
        subheading={<Subheading>About BFF</Subheading>}
        heading="Big Film Fund"
        buttonRounded={false}
        primaryButtonText="See Equity Opportunities"
        primaryButtonUrl="/"
      />
      {/*<MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />*/}
      {/*<Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />*/}
      <FAQS />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <FAQBFF />
    </AnimationRevealPage>
  );
};
