import * as ActionTypes from '../actions/userActions';

export const initialState = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    add_street1: null,
    add_street2: null,
    city: null,
    state: null,
    zip_code: null,
    country: null,
    tier: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return Object.assign({}, state, {
                id: action.id,
                first_name: action.first_name,
                last_name: action.last_name,
                email: action.email,
                phone: action.phone,
                add_street1: action.add_street1,
                add_street2: action.add_street2,
                city: action.city,
                state: action.state,
                zip_code: action.zip_code,
                country: action.country,
                tier: action.tier,
            });
        default:
            return state;
    }
};