//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../redux/store';
import axios from 'axios';
import { setUser } from '../redux/actions/userActions';
import LoadingIndicator from '../components/widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../redux/actions/appActions';
import withRouter from '../helpers/withRouter';

// import UserPasswordVerify from '../UserPasswordVerify';

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

const Container = tw(ContainerBase)`min-h-screen bg-secondary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const DividerTextContainer = tw.div`my-12 text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`cursor-pointer mt-5 tracking-wide font-semibold bg-secondary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-secondary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

// import { loadReCaptcha } from 'react-recaptcha-v3';
// import { ReCaptcha } from 'react-recaptcha-v3';


export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: { email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
            messages: {},
        };
    }




    handleFormSubmitLogin(event) {
        // event.preventDefault();
        // todo maybe hash the password here, but https should be enough security
        // var saltRounds = 10;
        var fData = new URLSearchParams();
        fData.append('email', this.state.fields['email']);
        fData.append('password', this.state.fields['password']);
        // bcrypt.hashSync(this.state.fields['password'], saltRounds).then(function(hash) {
        //     fData.append('password', hash);
        // });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        //post to login api to password verify and jwt response
        // console.log(this.state.fields['email']); // for console usage switchonoff
        // console.log(this.state.fields['password']); // for console usage switchonoff
        axios({
                method: 'post',
                url: 'http://127.0.0.1:8080/bff_api/user_login_jwt.php', // for testing usage switchonoff 
                // // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_login_jwt.php', // for production usage switchonoff 
                data: fData,
                config
            })
            // use the arrow to perform a function because this way for some reason keeps the state part of Login object and not part of the axios promise
            .then((response) => {
                //handle success
                // console.log(response.data); // for console usage switchonoff     
                this.setState({ fields: { email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
                                messages: {} 
                             });
                //jwt set to local storage

                if (response.status === 200 && response.data.jwt && response.data.expireAt) {
                  console.log(response.data);
                    let jwt = response.data.jwt;
                    let expire_at = response.data.expireAt;
                    let messages = {};
                    this.state.fields['id'] = response.data.id;
                    this.state.fields['first_name'] = response.data.first_name;
                    this.state.fields['last_name'] = response.data.last_name;
                    this.state.fields['email'] = response.data.email;
                    this.state.fields['phone'] = response.data.phone;
                    this.state.fields['add_street1'] = response.data.add_street1;
                    this.state.fields['add_street2'] = response.data.add_street2;
                    this.state.fields['city'] = response.data.city;
                    this.state.fields['state'] = response.data.state;
                    this.state.fields['zip_code'] = response.data.zip_code;
                    this.state.fields['country'] = response.data.country;
                    this.state.fields['tier'] = response.data.tier;
                    
                    //set localStorage of application user.  This data will be used until token expiry or logout
                    localStorage.setItem('id', this.state.fields['id'])
                    localStorage.setItem('access_token', jwt);
                    localStorage.setItem('expire_at', expire_at);
                    localStorage.setItem('tier', this.state.fields['tier']);

                    //reset the props to dispatch for setUser again after axios promise fulfilled                    
                    const {
                        setUser,
                        toggleSubscription,
                        is_subscription,
                    } = this.props;
                    
                    setUser(this.state.fields['id'], this.state.fields['first_name'], this.state.fields['last_name'], this.state.fields['email'], this.state.fields['phone'], this.state.fields['add_street1'], this.state.fields['add_street2'], this.state.fields['city'], this.state.fields['state'], this.state.fields['zip_code'], this.state.fields['country'], this.state.fields['tier']);
                    console.log(setUser);

                    if (this.state.fields['tier'] >= 1) {
                        toggleSubscription(!is_subscription);
                    }

                    //set user response success
                    // console.log(this.state)
                    //NOW THAT 
                    const state = store.getState();
                    
                } else if (response.status === 401 || response.data.match(/access denied/i)) {
                    console.log('Permission denied...');
                    let fields = this.state.fields;
                }

                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props;
                let messages = {}
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(this.state);                
            })
            .catch((error) => {
                //handle error
                let messages = {}
                // this.setState({ messages: {} });
                if (error.response) {
                    if (error.response.status === 500) {
                        // console.log('500 Unable to reach API server...');
                        messages['status'] = 'Unable to connect to server...';
                    } else {
                        // console.log(error)
                        messages['status'] = 'Error! - Invalid credentials...';
                    }
                    this.setState({ messages: messages });
                } else {
                    // messages['loginNo'] = 'Please wait...';
                }
                
                
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            });
    }

    handleFormSubmitResetPassword(event) {

        let messages = {};
        let fields = {};
        var fData = new URLSearchParams();
        fData.append('email', this.state.fields['email']);
        // console.log('RESET : : : ')
        // console.log(this.state.fields['email'])
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        //post to login api to password verify and jwt response
        axios({
                // method: 'get',
                method: 'post',
                url: 'http://127.0.0.1:8080/bff_api/user_reset_password.php', // for testing usage switchonoff
                // // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_reset_password.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                console.log(response.status); // for console usage switchonoff
                console.log(response.data); // for console usage switchonoff
                
                if (response.status === 200 && response.data.email !== 'mailfail') {
                    this.state.fields['verify_sent'] = true;
                    this.state.fields['email'] = response.data.email;
                    const {
                        setUser,
                    } = this.props;                    
                    setUser(null, null, null, this.state.fields['email'], null, null, null, null, null, null, null, null);

                    const { navigate } = this.props;                     
                    navigate('/pages/userpasswordverify');           
                } else if (response.status === 200 && response.data.email === 'mailfail' || response.status === 401) {
                    // console.log(response.data)
                    messages['status'] = 'No username or email address found.  Please enter your BSE username or email address you signed up with.'
                    this.setState({ messages: messages });
                    this.setState({ fields: { verify_sent:  this.state.fields['verify_sent'], email: '' } });
                }

                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }


    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }


    handleValidationLogin() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //email
        if (!fields['email']) {
            formIsValid = false;
            messages['email'] = 'Cannot be empty';
        } else if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                messages["email"] = "Error! - email is not valid format";
            }
        }
        //Password
        if (!fields['password']) {
            formIsValid = false;
            messages['password'] = 'Cannot be empty';
        } else if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].length >= 8) {
                formIsValid = false;
                messages["password"] = "Minimum length of password must be 8.";
            }
            if (!fields["password"].match(/[a-zA-Z]/) || !fields["password"].match(/[0-9]/) || !fields["password"].match(/_|-|!|\*|\./)) {
                formIsValid = false;
                messages["password"] = "Must include letters, number(s), and at least one symbol: '!', '_', '-', '*'";
            }
            //check for 
        }

        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }

    handleValidationResetPassword() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //email
        if (!fields['email']) {
            formIsValid = false;
            messages['email'] = 'Cannot be empty';
        } else if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                messages["email"] = "Error! - email is not valid format";
            }
        }

        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }



    checkSubmitLogin(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationLogin()) {

            toggleLoadingIndicator(!is_loading_indicator);
            // console.log('1 - ' + is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmitLogin();
            // this.recaptcha.execute();
        } else {
            // alert('Form has messages.');
        }
    }


    checkSubmitResetPassword(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationResetPassword()) {
            toggleLoadingIndicator(!is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmitResetPassword();
            // this.recaptcha.execute();
        } else {
            // alert('Form has messages.');
        }
    }



    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        fields['email'] = fields['email'];
        // console.log(field)
        this.setState({ fields });
        // console.log(this.state.fields);
    }



    render() {
        // const { setUser } = this.props;
        // const { id, username, email, newsletter, subscription, fields, messages } = this.props;        
        const {            
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;

        return (
            <AnimationRevealPage>
                <Container>
                  <Content>
                    <MainContainer>
                        <MainContent>
                            <Heading>Login</Heading>
                            <FormContainer>
                            {/*<SocialButtonsContainer>
                                {socialButtons.map((socialButton, index) => (
                                  <SocialButton key={index} href={socialButton.url}>
                                    <span className="iconContainer">
                                      <img src={socialButton.iconImageSrc} className="icon" alt=""/>
                                    </span>
                                    <span className="text">{socialButton.text}</span>
                                  </SocialButton>
                                ))}
                              </SocialButtonsContainer>*/}
                                <DividerTextContainer>
                                    <DividerText>Sign in with your e-mail</DividerText>
                                </DividerTextContainer>
                                <br />
                                <Form>
                                    {/*<button className='btn btn-primary' onClick = { this.register }>Sign up</button>*/}
                                    <label>Email</label> <span style={{color: 'red'}}>{this.state.messages.email}</span><br />
                                    <Input type='text' name='email' value={this.state.fields['email']} onLoad={this.handleChange.bind(this, 'email')} onChange={this.handleChange.bind(this, 'email')} />
                                    <br />
                                    <br />
                                    <label>Password</label> <span style={{color: 'red'}}>{this.state.messages['password']}</span><br />
                                    <Input type='password' name='password'  value={this.state.fields['password']} onLoad={this.handleChange.bind(this, 'password')} onChange={this.handleChange.bind(this, 'password')} />
                                    <br />                    
                                    <p style={{minHeight: '22px'}}><span style={{color: "red"}}>{this.state.messages["status"]}</span> </p>

                                    {(this.state.fields['email'].length === 0 || this.state.fields['password'].length === 0) ? 
                                    <SubmitButton className="disabled" type='submit'value='Login' disabled>Submit</SubmitButton>
                                    :
                                    <SubmitButton type='submit' onClick={e => this.checkSubmitLogin(e, is_loading_indicator, toggleLoadingIndicator)} value='Login'>Submit</SubmitButton>}

                                    {!this.state.fields['verify_sent'] && 
                                        <React.Fragment>

                                            {this.state.fields['email'].length === 0 ? <SubmitButton className="disabled" type="submit" value="Forgot Password" disabled>Forgot Password</SubmitButton> : <SubmitButton type="submit" onClick={e => this.checkSubmitResetPassword(e, is_loading_indicator, toggleLoadingIndicator)} value="Forgot Password">Forgot Password</SubmitButton>}

                                            <p><br /><small><strong>Forgot password?</strong><br />Type your email above first, and then click Forgot Password button.
                                            <br />
                                            A reset password email will be sent to email we have on record, that matches.</small></p>
                                            <p tw="mt-6 text-xs text-gray-600 text-center">
                                              I agree to abide by BFF's{" "}
                                              <a href='/pages/termsofservice' tw="border-b border-gray-500 border-dotted">
                                                Terms of Service
                                              </a>{" "}
                                              and its{" "}
                                              <a href='/pages/privacypolicy' tw="border-b border-gray-500 border-dotted">
                                                Privacy Policy
                                              </a>
                                            </p>
                                            <p tw="mt-8 text-sm text-gray-600 text-center">
                                                Dont have an account?{" "}
                                                <a href='/pages/signup' tw="border-b border-gray-500 border-dotted">
                                                    Sign Up
                                                </a>
                                            </p>
                                        </React.Fragment>
                                    }
                                </Form>                             
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                      <IllustrationImage imageSrc={illustration} />
                    </IllustrationContainer>
                    </Content>
                </Container>
            </AnimationRevealPage>

        );
    }
}


export const mapStateToProps = state => ({
     id: state.user.id,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    email: state.user.email,
    phone: state.user.phone,
    add_street1: state.user.add_street1,
    add_street2: state.user.add_street2,
    city: state.user.city,
    state: state.user.state,
    zip_code: state.user.zip_code,
    country: state.user.country,
    tier: state.user.tier,
    is_loading_indicator: state.app.is_loading_indicator,
});
export const mapDispatchToProps = dispatch => ({
    setUser: (id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier) => dispatch(setUser(id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
// export default Login;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));