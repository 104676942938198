
//copyright: Big Screen Entertainment Group Inc.
export const SET_USER = 'SET_USER';

export function setUser(id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier) {
  return {
    type: SET_USER,
    id,
    first_name,
    last_name,
    email,
    phone,
    add_street1,
    add_street2,
    city,
    state,
    zip_code,
    country,
    tier,
  };
}