import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingLg } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`tiny:mt-12 sm:mt-80 md:mt-72 lg:mt-64 -mx-8 px-8 text-secondary-500`;

const HeadingContainer = tw.div`py-0`;
const Subheading = tw(SubheadingBase)`text-center text-secondary-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-secondary-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-secondary-300 hover:border-secondary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-secondary-300`;

export default ({
  subheading = "",
  heading = "Project Investment Info",
  description = "Here you can find FAQs about how the investment is structured.",
  faqs = [
    {
      question: "Profit-Making Potential",
      answer:
        "Our crowdfunding approach focuses on the financial aspect, allowing investors to benefit from the film's success and generate returns on their investment for years to come."
    },
    {
      question: "Financial Returns",
      answer:
        "Investors can earn dividends and royalties from the film's performance."
    },
    {
      question: "Affordable Entry",
      answer:
        "We offer an easy entry point, enabling anyone to become an equity holder on a movie with an investment as little as $100."
    },
    {
      question: "Comprehensive Rewards",
      answer:
        "In addition to financial benefits, our platform will offer exciting perks like film credits, premiere invitations, and the opportunity to interact with cast and crew."
    },
    {
      question: "Return on Investment",
      answer:
        "Investors will receive 120% upon sales of the film after distribution costs, then a profit sharing percentage per your investment. All sales for each film are collected into a transparent accounting fund per film."
    },
    {
      question: "Transparent Accounting",
      answer:
        "Each project is setup as an individual LLC entity, in which your investment gets recorded by the transfer agent.  This way all revenue and distributions properly gets reported to each shareholder."
    }  
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingLg>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingLg>
    </PrimaryBackgroundContainer>
  );
};
