//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../redux/store';
import axios from 'axios';
import { setUser } from '../redux/actions/userActions';
import LoadingIndicator from '../components/widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../redux/actions/appActions';
import withRouter from '../helpers/withRouter';

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

const Container = tw(ContainerBase)
`min-h-screen bg-secondary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-12 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;
const DividerTextContainer = tw.div `my-2 py-6 text-center relative`;
const DividerText = tw.div `leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`cursor-pointer mt-5 tracking-wide font-semibold bg-secondary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-secondary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div `sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div `
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export class SignUpVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: { 
                verify_code: '',
                email: '' 
            },
            messages: {
                status: '',
                verify_sent: false
            },
        };
    }


    handleSubmitVerifyResend() {
        let messages = {};
        // event.preventDefault();
        var fData = new URLSearchParams();
        console.log(this.state.fields['email']);
        fData.append('email', this.state.fields['email']);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                url: 'http://127.0.0.1:8080/bff_api/user_register_resend.php', // for testing usage switchonoff
                // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register_resend.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                console.log(response)
                this.setState({ messages: {} });
                if (response.data === 1) {
                    messages['verify_sent'] = true;
                    messages['status'] = 'Resent email success.'
                } else if (response.data.match(/mailer error/i)) {
                    messages['verify_sent'] = false;
                    messages['email'] = 'Error e-mail not sent.'
                }
                this.setState({ messages: messages });
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                console.log(this.state.messages['status']);
            })
            .catch((error) => {
                //handle error
                this.setState({ messages: {} });
                messages['email'] = 'Error e-mail not sent.'
                
                this.setState({ messages: messages });
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }

    handleSubmitVerifyCode() {
        let messages = {};
        var fData = new URLSearchParams();
        const state = store.getState();
        console.log(this.state.fields['verify_code']);
        console.log(state.user.email);
        fData.append('email', state.user.email);
        // console.log(this.username);
        fData.append('verify_code', this.state.fields['verify_code']);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                url: 'http://127.0.0.1:8080/bff_api/user_register_verify.php', // for testing usage switchonoff
                // url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register_verify.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                console.log(response.data);  // for console usage switchonoff
                let messages = {};
                if (response.status === 200 && response.data.id) {
                    this.id = response.data.id;                  
                    this.email = response.data.email;
                    this.tier = response.data.tier;                    
                    messages['verify_sent'] = '';
                    messages['status'] = 'Verification successful!';
                    const {
                        setUser,
                        // toggleSubscription,
                        // is_subscription,
                        // location,
                        // history
                    } = this.props;
                    setUser(this.id, '', '', this.email, '', '', '', '', '', '', '', this.tier);
                } else if (response.data.match(/code\s*(expired|not found)/i)) {
                    messages['verify_sent'] = false;
                    messages['verify_code'] = response.data;
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                this.setState({ messages: messages });
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error); // for console usage switchonoff
            });
    }


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidation('email')) {
            toggleLoadingIndicator(!is_loading_indicator);               
            this.handleSubmitVerifyResend();
        }
    }

    
    handleVerifyCode(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidation('verify_code')) {
            toggleLoadingIndicator(!is_loading_indicator);
            console.log(is_loading_indicator);         
            this.handleSubmitVerifyCode();
        }
    }

    handleValidation(formType) {

        let fields = this.state.fields;
        let messages = {};
        let formIsValid = true;
        //First Name validation empty
        console.log(fields)

        //formType: email verify_code

        if (!fields[formType]) {
            formIsValid = false;
            if (formType === 'email') {
                messages["email"] = "Error! - cannot be empty";
            } else if (formType === 'verify_code') {
                messages["verify_code"] = "Error! - cannot be empty";
            }
        } else if (formType === 'email') {
            if (typeof fields["email"] !== "undefined") {
                let lastAtPos = fields["email"].lastIndexOf('@');
                let lastDotPos = fields["email"].lastIndexOf('.');
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                    formIsValid = false;
                    messages["email"] = "Error! - email is not valid format";
                }
            }
        }
        this.setState({ messages: messages });
        console.log(formIsValid)
        return formIsValid;
    }
    

    render() {
        const {
            setUser,
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;
        // let access_token = localStorage.getItem('access_token');
        // let expire_at = localStorage.getItem('expire_at');
        const state = store.getState();
        this.id = state.user.id;
        this.email = state.user.email;
        this.tier = state.user.tier;

        return (

            <AnimationRevealPage>
            {/*<Helmet>
                <meta charSet="utf-8" />
                <title>BSE - User Register Verify</title>
                <link rel="canonical" href="https://bigstreamentertainment.com/SignUpVerify" />
                <meta name="description" content="BSE - Big Stream Entertainment streaming and entertainment portal user register verify page (Stock: BSEG)" />
                <meta name="keywords" content="big stream, bseg, big screen entertainment, big stream, entertainment, user register verify" />
            </Helmet>*/}
              <Container>
                <Content>
                  <MainContainer>
                    <MainContent>
                      <Heading>BFF Verify Sign Up</Heading>
                      <FormContainer>
                        <DividerTextContainer>
                          <DividerText><p>Thank you for registering!</p><br /><p>Verify Your Email</p></DividerText>
                        </DividerTextContainer>
                        <div style={{maxWidth: '350px', margin: '0 auto'}}>
                        <p style={{textAlign: 'justify', marginBottom: '1rem'}}>To enable your BFF account please check your email you registered with to get your 8 digit verification code.</p>
                        <p style={{textAlign: 'justify', marginBottom: '1rem'}}>Please allow a few minutes for email to arrive, if you do not see the email from Big Film Fund in your inbox, please check your spam folder also.</p>
                        </div>
                        <br />
                        <Form>
                            <label>Verify Code<br /><span style={{color: "green"}}>{this.state.messages["status"]}</span><span style={{color: "red"}}>{this.state.messages["verify_code"]}</span><br /><small>(Enter verification code from sign-up email)</small></label>
                            <br />
                            <Input type='text' name='verify_code' value={this.state.fields['verify_code']} onChange={this.handleChange.bind(this, 'verify_code')} placeholder="Enter verify code..." />
                            <br />
                            <SubmitButton type="submit" onClick={e => this.handleVerifyCode(e, is_loading_indicator, toggleLoadingIndicator)} value="Verify Code">Verify Code</SubmitButton>
                            <br />
                            <br />
                            {this.state.messages['verify_sent'] === true ?
                                <React.Fragment>
                                    <p>Email verification re-sent, please allow a few minutes. Check your inbox for the verification email, if not there please check your spam box. Thank you.</p>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                <label>Resend verification email<br /><span style={{color: "red"}}>{this.state.messages["email"]}</span><br /><small>(If your code expired or you lost your code you can have another verification code sent to your email.)</small></label>
                                <Input type='email' name='email' value={this.state.fields['email']} onChange={this.handleChange.bind(this, 'email')} placeholder="Enter sign-up email..." />
                                <SubmitButton type="submit" onClick={e => this.handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator)} value="ReSend Verify Email">Resend Verify Email</SubmitButton>
                                <br />
                                </React.Fragment>
                            }
                        </Form>    
                        <p tw="mt-6 text-xs text-gray-600 text-center">
                            I agree to abide by BFF's{" "}
                            <a href='/pages/termsofservice' tw="border-b border-gray-500 border-dotted">
                              Terms of Service
                            </a>{" "}
                            and its{" "}
                            <a href='/pages/privacypolicy' tw="border-b border-gray-500 border-dotted">
                              Privacy Policy
                            </a>
                          </p>
                          <p tw="mt-8 text-sm text-gray-600 text-center">
                            Already have an account?
                            <a href="/pages/login" tw="border-b border-gray-500 border-dotted">
                              Sign In
                            </a>
                          </p>       
                        </FormContainer>
                    </MainContent>
                  </MainContainer>
                  <IllustrationContainer>
                    <IllustrationImage imageSrc={illustration} />
                  </IllustrationContainer>
                </Content>
              </Container>
            </AnimationRevealPage>
        );
    };
}

export const mapStateToProps = state => ({
    id: state.user.id,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    email: state.user.email,
    phone: state.user.phone,
    add_street1: state.user.add_street1,
    add_street2: state.user.add_street2,
    city: state.user.city,
    state: state.user.state,
    zip_code: state.user.zip_code,
    country: state.user.country,
    tier: state.user.tier,
    is_loading_indicator: state.app.is_loading_indicator,
});

export const mapDispatchToProps = dispatch => ({
    setUser: (id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier) => dispatch(setUser(id, first_name, last_name, email, phone, add_street1, add_street2, city, state, zip_code, country, tier)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpVerify));