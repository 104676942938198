import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

/* Ready Made Pages (from demos folder) */
import Home from "containers/Home.js";

/* Inner Pages */
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import SignUpVerify from "pages/SignUpVerify.js";
import UserPasswordVerify from "pages/UserPasswordVerify.js";
// import PricingPage from "pages/Pricing.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import Disclaimer from "pages/Disclaimer.js";
import InvestSingleMiracleRanch from "projects/InvestSingleMiracleRanch.js";
import InvestSingleTheRhymer from "projects/InvestSingleTheRhymer.js";
import InvestSingleCinderella from "projects/InvestSingleCinderella.js";
import InvestSingleEarthAngels from "projects/InvestSingleEarthAngels.js";
import InvestSingleKeyToChristmas from "projects/InvestSingleKeyToChristmas.js";
import InvestSingleItsAWonderfulWife from "projects/InvestSingleItsAWonderfulWife.js";
import InvestFundHoliday from "projects/InvestFundHoliday.js";
import InvestFundHorror from "projects/InvestFundHorror.js";
import InvestFundFaith from "projects/InvestFundFaith.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import Header from "components/headers/light.js"
import Footer from "components/footers/SimpleFiveColumn.js";

// import ComponentRenderer from "ComponentRenderer.js";

import ThankYouPage from "pages/ThankYouPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
      <Header />
        <Routes>
          {/*<Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />*/}
          {/*<Route path="/components/:type/:name" element={<ComponentRenderer />} />*/}
          {/*<Route path="/pages/BlogIndexPage" element={<BlogIndexPage />} />*/}
          <Route path="/projects/investsinglemiracleranch" element={<InvestSingleMiracleRanch />} />
          <Route path="/projects/investsingletherhymer" element={<InvestSingleTheRhymer />} />
          <Route path="/projects/investsinglecinderella" element={<InvestSingleCinderella />} />
          <Route path="/projects/investsingleearthAngels" element={<InvestSingleEarthAngels />} />
          <Route path="/projects/investsinglekeytochristmas" element={<InvestSingleKeyToChristmas />} />
          <Route path="/projects/investsingleitsaWonderfulwife" element={<InvestSingleItsAWonderfulWife />} />
          <Route path="/projects/investFundHoliday" element={<InvestFundHoliday />} />
          <Route path="/projects/investFundHorror" element={<InvestFundHorror />} />
          <Route path="/projects/investFundFaith" element={<InvestFundFaith />} />
          <Route path="/pages/login" element={<LoginPage />} />
          <Route path="/pages/signup" element={<SignupPage />} />
          <Route path="/pages/signupverify" element={<SignUpVerify />} />
          <Route path="/pages/userpasswordverify" element={<UserPasswordVerify />} />
          <Route path="/pages/contactuspage" element={<ContactUsPage />} />
          <Route path="/pages/thankyou" element={<ThankYouPage />} />
          <Route path="/pages/termsofservice" element={<TermsOfServicePage />} />
          <Route path="/pages/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/pages/aboutus" element={<AboutUsPage />} />
          <Route path="/pages/disclaimer" element={<Disclaimer />} />
          <Route path="/" element={<Home />} />
        </Routes>
      <Footer />
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
