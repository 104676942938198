import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/invest/bff_invest_2024_therhymer.jpg";

import Feature from "./TwoColWithButton.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";

//highlihgts
const HighlightedText = tw.span`bg-secondary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => {
  
  return (
    <AnimationRevealPage>
      <Feature
        heading = "Featured Film"
        subheading={<><HighlightedText>Miracle Ranch</HighlightedText></>}
        description="A troubled young woman is determined to self-destruct and take her foster family with her when a miraculous rescue horse teaches her to live, love, and thrive again."
        budgetdescription="Budget: $750,000"
        primaryButtonText="Invest Now"
        primaryButtonUrl="/projects/investsinglemiracleranch"
        watchVideoButtonText="Watch Video"
        watchVideoYoutubeUrl="https://www.youtube.com/embed/X3sK_-cfZHY"
        imageSrc="https://bigscreenentgroup.com/images/indevelopment/cover_miracle_ranch.jpg"
        imageCss={null}
        imageDecoratorBlob={false}
        colCss="flex-direction: row-reverse; @media screen and (max-width: 1023px) { flex-direction: column; } }"
      />      
      <Feature
        heading = "Feature Film"
        subheading={<><HighlightedText>The Promise</HighlightedText></>}
        description="A young woman--having just shunned a homeless beggar--is severely injured and makes a promise to God on her deathbed that she will change the course of her life and help others. A love story with themes of adoption, motherhood, and parenting."
        budgetdescription="Budget: $450,000"
        primaryButtonText="Invest Now"
        primaryButtonUrl="/projects/investsingletherhymer"
        watchVideoButtonText="Watch Video"
        watchVideoYoutubeUrl="https://www.youtube.com/embed/X3sK_-cfZHY"
        imageSrc={DesignIllustration}
        imageCss={null}
        imageDecoratorBlob={false}
        // colCss="flex-direction: row;"
      />      
    </AnimationRevealPage>
  );
};
