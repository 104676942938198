import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";

import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import TheCover from "../images/invest/bff_invest_2024_cinedrella.jpg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-top rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-secondary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-secondary-500 after:w-8`;
const Funded = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Budget = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-secondary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
const InvestButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Invest in Cinderella",
  posts = [
    {
      imageSrc:
        TheCover,
      category: "Fairy Tale",
      funded: "Amount funded: $0.00",
      budget: "Budget: $1,000,000",
      description:
        "In a world where magic is real, Cinderella's cunning and hidden powers are her only hope against her wicked stepmother and stepsisters, who possess dark and sinister magic. As she rediscovers her magical heritage, she must confront the forces of evil in an epic battle of wits and sorcery, where the destiny of her kingdom is up to her.",
      url: "/pages/contactuspage",
      featured: true
    }
   
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      {/*{/*<Header />*/}*/}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                {/*{<Post className="group">}*/}
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <Budget>{post.budget}</Budget>
                    <Funded>{post.funded}</Funded>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                    <ButtonContainer>
                    <InvestButton href={post.url}>Invest Now</InvestButton>
                    </ButtonContainer>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
