import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";

import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div `flex`;
const Heading = tw(SectionHeading)
`text-gray-900 mb-10`;
const Text = styled.div `
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Disclaimers" }) => {
    return (
        <AnimationRevealPage>
      {/*<Header />*/}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: Jan. 30, 2024</p>

            
            <h1>Investor Disclaimer and Forward Looking Statements</h1>
            <h2>Forward Looking Statements</h2>
            <p>About Big Screen Entertainment Group (Stock: BSEG) and its wholly owned subsidiary Big Film Fund.</p>
            <p>Big Screen Entertainment Group (OTC:BSEG), and its 100% wholly owned subsidiary Big Film Fund is a well-established media funding, distribution and production company based in Beverly Hills. Built on a love for storytelling, the business launched in 2005 and has grown to specialize in production, post-production, distribution in the US and Internationally.</p>
            <p>Forward-Looking Statement: A number of statements contained in this site are forward-looking statements that are made pursuant to the safe harbor provisions of the Private Securities Litigation Act of 1995. These forward-looking statements involve a number of risks and uncertainties, including timely development, and market acceptance of products and technologies, competitive market conditions, successful integration of acquisitions and the ability to secure additional sources of financing. When used in this press release, words such as “could,” “plan,” “estimate,” “expect,” “intend,” “may,” “potential,” “should,” and similar expressions are forward-looking statements.</p>

            <h1>Disclaimers and Info</h1>
            <h2>Investment Disclaimer</h2>
            <p>All content on this site is for informational purposes of our products and services of the Big Film Fund. Do not construe any such information or material as legal, tax, investment, financial, professional or any other advice. Content on our site does not represent or constitute any solicitation, inducement, recommendation, endorsement or offer by Big Film Fund or any third party service provider to buy or sell securities or any financial instruments and services. Nothing on our sites constitutes professional and/or financial advice, nor does any information found on this site constitute a comprehensive or complete statement of the matters discussed. Big Film Fund is not a fiduciary by virtue of any person's use of or access to this site and content. Any information, materials, statements and/or data set out herein is subject to change anytime without notice and as such, no reliance must be placed on fairness, accuracy, completeness or correctness of any information and materials contained on this site.</p>
            <p>You alone assume the sole responsibility of evaluating all merits and risks that are or may be associated with any use of any information or material on the site or coming into any conclusion based on the information and content found on the site. In exchange of using the site, you agree not to hold Big Film Fund, its affiliates or any third party service provider(s) liable for any possible claim for damages arising from any decisions you may or had made based on the information or content made available to you on the site. In any event, Big Film Fund and/or its employees, advisors and representatives is not liable for any loss or damage whatsoever arising or incurred from any use or reliance of this site, its contents or otherwise arising in connection with.</p>
            <p>There are risks associated with investing in securities, commodities and property. Investing over securities and commodities such as stocks, bonds, exchange traded funds, mutual funds, money market funds, cryptocurrency, private equity, involve risk of loss. Loss of principal is possible.</p>
            <p>To better provide you with information, Big Film Fund may provide hyperlinks to websites operated and/or snapshots of information provided by or derived from third parties. In selecting these hyperlinks, you are no longer on Big Film Funds's operated site. As Big Film Fund has no control over such sites or content, Big Film Fund will not assume any responsibility over the availability of such external sites or their content. Big Film Fund does not adopt, endorse, nor is responsible or liable for any such sites or content, including advertising, products or other materials, on or is made available through such third parties' sites or resources. Other websites may offer links to our site and/or content with or without our prior authorization. Big Film Fund does not endorse any such sites and shall not be responsible or liable for any links from those sites to our site and content, or for any content, advertising, products or other materials made available on or through such other sites, or any loss or damages incurred in connection therewith. Big Film Fund may, in our sole discretion, block links to the site and content without any prior notice.</p>
            <ul>
              <li>
                <a href="/pages/termsofservice">Terms of Service</a>
              </li>
              <li>
                <a href="/pages/privacypolicy">Privacy Policy</a>
              </li>
            </ul>
            <h1>Contact Us</h1>
            <p>If you have any questions about these disclaimers and statements, You can contact us:</p>

            <ul>
              <li>By email: big@bigscreenent.com</li>
              <li>By phone number: +1 323-654-3400</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
    );
};