
//copyright: Big Screen Entertainment Group Inc.
import { combineReducers } from 'redux';
import app from './reducers/appReducer';
import user from './reducers/userReducer';
import custom1 from './reducers/customReducer';

export default combineReducers({
  app,
  user,
  custom1,
});