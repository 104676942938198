import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import InvestFunds from "components/invest/InvestFunds.js";
import InvestProjects from "components/invest/InvestProjects.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";
// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Newsletter from "components/forms/SimpleSubscribeNewsletter.js";


import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import InvestFeatured from "components/invest/InvestFeatured.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <InvestFeatured />
    <InvestFunds />
    <InvestProjects />
    <ContactUsForm />
    <Newsletter />
  </AnimationRevealPage>
);
